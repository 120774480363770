import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import title from './title';
import user from './user';
import common from './common';

const debug = process.env.NODE_ENV !== 'production';
export default createStore({
  modules: {
    title,
    user,
    common,
  },
  strict: debug,
  plugins: [createPersistedState({
    storage: window.sessionStorage, // 或者 localStorage
  })]
});
