import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import util from '../utils/util';

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    alias: '/updateRiskTropism',
    name: 'Login',
    // eslint-disable-next-line max-len
    component: () => (util.getIsKH() ? import('../views/Login.vue') : import('../views/Login_Isda.vue')),
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
  },
  // 基本资料
  {
    path: '/info/step_one',
    name: 'info_step_one',
    component: () => import('../views/info/Step_one.vue'),
  },
  {
    path: '/info/step_two',
    name: 'info_step_two',
    component: () => import('../views/info/Step_two.vue'),
  },
  {
    path: '/info/step_three',
    name: 'info_step_three',
    component: () => import('../views/info/Step_three.vue'),
  },
  {
    path: '/info/step_four',
    name: 'info_step_four',
    component: () => import('../views/info/Step_four.vue'),
  },
  {
    path: '/info/step_five',
    name: 'info_step_five',
    component: () => import('../views/info/Step_five.vue'),
  },
  // 风险认知
  {
    path: '/assess/index',
    name: 'assess_index',
    meta: { keepAlive: true },
    component: () => import('../views/assess/index.vue'),
  },
  {
    path: '/assess/report',
    name: 'assess_report',
    component: () => import('../views/assess/report.vue'),
  },
  {
    path: '/assess/sense',
    name: 'assess_sense',
    component: () => import('../views/assess/sense.vue'),
  },
  {
    path: '/assess/step_one',
    name: 'assess_step_one',
    component: () => import('../views/assess/step_one.vue'),
  },
  // 专业投资者选项
  {
    path: '/majorOption/step_one',
    name: 'majorOption_step_five',
    component: () => import('../views/majorOption/Step_one.vue'),
  },
  {
    path: '/majorOption/step_two',
    name: 'majorOption_step_two',
    component: () => import('../views/majorOption/Step_two.vue'),
  },
  {
    path: '/majorOption/step_three',
    name: 'majorOption_step_three',
    component: () => import('../views/majorOption/Step_three.vue'),
  },
  {
    path: '/majorOption/appendix',
    name: 'majorOption_appendix',
    component: () => import('../views/majorOption/Appendix.vue'),
  },
  // 投资风险取向
  {
    path: '/riskOrientation/step_one',
    name: 'riskOrientation_step_one',
    component: () => import('../views/riskOrientation/Step_one.vue'),
  },
  {
    path: '/riskOrientation/step_two',
    name: 'riskOrientation_step_two',
    component: () => import('../views/riskOrientation/Step_two.vue'),
  },
  {
    path: '/riskOrientation/step_three',
    name: 'riskOrientation_step_three',
    component: () => import('../views/riskOrientation/Step_three.vue'),
  },
  {
    path: '/riskOrientation/result',
    name: 'riskOrientation_result',
    component: () => import('../views/riskOrientation/Result.vue'),
  },
  // 上传资料
  {
    path: '/upload/add',
    name: 'upload_index_add',
    component: () => import('../views/upload/indexAdd.vue'),
  },
  {
    path: '/upload/index',
    name: 'upload_index',
    component: () => import('../views/upload/index.vue'),
  },
  {
    path: '/upload/adress',
    name: 'upload_adress',
    component: () => import('../views/upload/Adress.vue'),
  },
  {
    path: '/upload/assets',
    name: 'upload_assets',
    component: () => import('../views/upload/Assets.vue'),
  },
  {
    path: '/upload/bank',
    name: 'upload_bank',
    component: () => import('../views/upload/Bank.vue'),
  },
  {
    path: '/upload/id',
    name: 'upload_id',
    component: () => import('../views/upload/Id.vue'),
  },
  {
    path: '/upload/picture',
    name: 'upload_picture',
    component: () => import('../views/upload/Picture.vue'),
  },
  {
    path: '/upload/transfer',
    name: 'upload_transfer',
    component: () => import('../views/upload/TransferAcc.vue'),
  },
  {
    path: '/upload/result',
    name: 'upload_result',
    component: () => import('../views/upload/Result.vue'),
  },
  {
    path: '/choose/index',
    name: 'choose_index',
    meta: { keepAlive: true },
    component: () => import('../views/choose/index.vue'),
  },
  {
    path: '/choose/futures',
    name: 'choose_futures',
    component: () => import('../views/choose/futures.vue'),
  },
  {
    path: '/choose/need',
    name: 'choose_need',
    component: () => import('../views/choose/need.vue'),
  },
  {
    path: '/choose/common',
    name: 'choose_common',
    component: () => import('../views/choose/common.vue'),
  },
  {
    path: '/choose/stock',
    name: 'choose_stock',
    component: () => import('../views/choose/stock.vue'),
  },
  {
    path: '/choose/hkidrOtcr',
    name: 'hkidr_otcr',
    component: () => import('../views/choose/hkidrOtcr.vue'),
  },
  {
    path: '/search/index',
    name: 'search_index',
    component: () => import('../views/search/index.vue'),
  },
  {
    path: '/search/riskUpdate',
    name: 'search_risk_update',
    component: () => import('../views/search/riskUpdate.vue'),
  },
  {
    path: '/check',
    name: 'check',
    component: () => import('../views/search/check.vue'),
  },
  {
    path: '/search/addAccount',
    name: 'search_add_account',
    component: () => import('../views/search/addAccount.vue'),
  },
  // 风险披露
  {
    path: '/riskDisclosure/step_one',
    name: 'riskDisclosure_step_one',
    component: () => import('../views/riskDisclosure/Step_one.vue'),
  },
  {
    path: '/riskDisclosure/step_two',
    name: 'riskDisclosure_step_two',
    component: () => import('../views/riskDisclosure/Step_two.vue'),
  },
  {
    path: '/isda/search',
    name: 'isda_search',
    component: () => import('../views/isda/search.vue'),
  },
  {
    path: '/isda/aggrement',
    name: 'isda_aggrement',
    component: () => import('../views/isda/aggrement.vue'),
  },
  {
    path: '/isda/result',
    name: 'isda_result',
    component: () => import('../views/isda/result.vue'),
  },
  {
    path: '/isda/baal',
    name: 'isda_BAAL',
    component: () => import('../views/isda/a_baal.vue'),
  },
  {
    path: '/isda/bis',
    name: 'isda_bis',
    component: () => import('../views/isda/a_bis.vue'),
  },
  {
    path: '/isda/biccs',
    name: 'isda_biccs',
    component: () => import('../views/isda/a_biccs.vue'),
  },
  {
    path: '/isda/bmc',
    name: 'isda_bmc',
    component: () => import('../views/isda/a_bmc.vue'),
  },
  {
    path: '/isda/bme',
    name: 'isda_bme',
    component: () => import('../views/isda/a_bme.vue'),
  },
  {
    path: '/isda/dab',
    name: 'isda_dab',
    component: () => import('../views/isda/a_dab.vue'),
  },
  {
    path: '/isda/ima',
    name: 'isda_ima',
    component: () => import('../views/isda/a_ima.vue'),
  },
  {
    path: '/isda/account',
    name: 'isda_account',
    component: () => import('../views/isda/account.vue'),
  },
  {
    path: '/isda/choose',
    name: 'isda_choose',
    component: () => import('../views/isda/choose.vue'),
  },
  {
    path: '/isda/openSubAccount',
    name: 'isda_openSubAccount',
    component: () => import('../views/openSubAccount/index.vue'),
  },
  {
    path: '/isda/openSubAccount/result',
    name: 'isda_openSubAccount_result',
    component: () => import('../views/openSubAccount/result.vue'),
  },
  {
    path: '/isda/openSubAccount/search',
    name: 'isda_openSubAccount_search',
    component: () => import('../views/openSubAccount/search.vue'),
  },
  {
    path: '/change/index',
    name: 'change_index',
    component: () => import('../views/change/index.vue'),
  },
  {
    path: '/change/result',
    name: 'change_result',
    component: () => import('../views/change/result.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.platform === 'AppDark') {
    store.commit('setDark', true);
    document.body.classList.add('dark-mode');
    store.commit('setPlatform', 'APP');
  }
  // if (to.query.theme === 'dark') {
  //   store.commit('setDark', true);
  //   document.body.classList.add('dark-mode');
  // }
  // if (to.query.platform === 'APP') {
  //   store.commit('setPlatform', 'APP');
  // }
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;

  // 开发状态关闭
  // const { isLogin } = store.state.user;
  // if (process.env.NODE_ENV !== 'development') {
  //   if (to.name !== 'Login' && to.name !== 'isda_aggrement' && to.name !== 'isda_search') {
  //     if (!isLogin) {
  //       window.location.replace(`${window.location.origin}/login`);
  //     }
  //   }
  // }
  next();
});

export default router;
