import { Toast } from 'vant';
import { PostJSON, PostFile } from './http';

/** ----------补充开户------------ */
const getOpenAccountInfo = () => PostJSON('/messenger/addOpenAccount/getOpenAccountInfo.json', {}, { title: '获取补开户信息' });
const clearAddAccountInfo = () => PostJSON('/messenger/addOpenAccount/clearAddAccountInfo.json', {}, { title: '重填补开户信息' });
const getAccountAgreementStatus = () => PostJSON('/messenger/addOpenAccount/getAccountAgreementStatus.json', {}, { title: '获取补开户类型以及是否已阅读各协议' });
const updateAccountAgreementStatus = (form) => PostJSON('/messenger/addOpenAccount/updateAccountAgreementStatus.json', form, { title: '更新补开户类型以及是否已阅读各协议' });
const getDerivativeAssessmentChoices = () => PostJSON('/messenger/addOpenAccount/getDerivativeAssessmentChoices.json', {}, { title: '获取补开户已勾选的衍生品认知评估选项' });
const updateDerivativeAssessmentChoices = (form) => PostJSON('/messenger/addOpenAccount/updateDerivativeAssessmentChoices.json', form, { title: '补开户更新已勾选的衍生品认知评估选项' });
const getDerivativeKnowledgeAssessmentChoices = () => PostJSON('/messenger/addOpenAccount/getDerivativeKnowledgeAssessmentChoices.json', {}, { title: '获取补开户已勾选的衍生工具知识评估选项' });
const updateDerivativeKnowledgeAssessmentChoices = (form) => PostJSON('/messenger/addOpenAccount/updateDerivativeKnowledgeAssessmentChoices.json', form, { title: '补开户更新已勾选的衍生工具知识评估选项' });
const getMajorInvesterInfo = () => PostJSON('/messenger/addOpenAccount/getMajorInvesterInfo.json', {}, { title: '获取补开户已勾选的专业投资者选项以及是否已阅读相关协议' });
const updateMajorInvesterInfo = (form) => PostJSON('/messenger/addOpenAccount/updateMajorInvesterInfo.json', form, { title: '补开户更新已勾选的专业投资者选项以及是否已阅读相关协议' });
const getMajorAssessInfo = () => PostJSON('/messenger/addOpenAccount/getMajorAssessInfo.json', {}, { title: '获取补开户已填写的专业投资者评估信息' });
const updateMajorAssessInfo = (form) => PostJSON('/messenger/addOpenAccount/updateMajorAssessInfo.json', form, { title: '补开户更新已填写的专业投资者评估信息' });
const getMajorConfirmInfo = () => PostJSON('/messenger/addOpenAccount/getMajorConfirmInfo.json', {}, { title: '获取补开户专业投资者确认信息' });
const updateMajorConfirmInfo = (form) => PostJSON('/messenger/addOpenAccount/updateMajorConfirmInfo.json', form, { title: '补开户更新专业投资者确认信息' });
const getRiskTropism = () => PostJSON('/messenger/addOpenAccount/getRiskTropism.json', {}, { title: '获取补开户投资风险取向答案' });
const updateRiskTropism = (form) => PostJSON('/messenger/addOpenAccount/updateRiskTropism.json', form, { title: '补开户提交投资风险取向答案' });
const getRiskTropismAssessResult = () => PostJSON('/messenger/addOpenAccount/getRiskTropismAssessResult.json', {}, { title: '补开户获取投资风险评估结果' });
const updateRiskConfirmInfo = (form) => PostJSON('/messenger/addOpenAccount/updateRiskConfirmInfo.json', form, { title: '补开户提交风险结果确认人输入内容' });
const getRiskTellStatus = () => PostJSON('/messenger/addOpenAccount/getRiskTellStatus.json', {}, { title: '获取补开户风险披露状态' });
const updateRiskTellStatus = (form) => PostJSON('/messenger/addOpenAccount/updateRiskTellStatus.json', form, { title: '补开户提交风险披露状态' });
const getVideoType = () => PostJSON('/messenger/addOpenAccount/getVideoType.json', {}, { title: '补开户获取音频编号' });
const updateRiskTellSign = (form) => PostJSON('/messenger/addOpenAccount/updateRiskTellSign.json', form, { title: '补开户上传风险披露签名' });
const getRiskTellSign = () => PostJSON('/messenger/addOpenAccount/getRiskTellSign.json', {}, { title: '获取上传风险披露签名' });
const getImageResourcesStatus = () => PostJSON('/messenger/addOpenAccount/getImageResourcesStatus.json', {}, { title: '补开户获取是否已上传各电子副本' });
const submitInfo = () => PostJSON('/messenger/addOpenAccount/submitInfo.json', {}, { title: '补开户上传资料提交' });
const getImageResource = (form) => PostJSON('/messenger/addOpenAccount/getImageResource.json', form, { title: '补开户按类型获取各电子副本' });
const uploadVideo = (form) => PostFile('/messenger/addOpenAccount/uploadVideo.json', form, { title: '上传视频' });

/** 上传PDF */
const uploadPdf = (type, form) => {
  const oneMb = 1024 * 1024;
  if (form.get('file').size / oneMb >= 5) {
    Toast('请上传5MB以内的pdf文件');
    return null;
  }
  return PostFile(`/messenger/addOpenAccount/uploadPdf.json?type=${type}`, form, { title: '上传PDF' });
};
const updateImageResource = (form) => PostJSON('/messenger/addOpenAccount/updateImageResource.json', form, { title: '按类型上传各电子副本' });

export default {
  getOpenAccountInfo,
  clearAddAccountInfo,
  getAccountAgreementStatus,
  updateAccountAgreementStatus,
  getDerivativeAssessmentChoices,
  updateDerivativeAssessmentChoices,
  getDerivativeKnowledgeAssessmentChoices,
  updateDerivativeKnowledgeAssessmentChoices,
  getMajorInvesterInfo,
  updateMajorInvesterInfo,
  getMajorAssessInfo,
  updateMajorAssessInfo,
  getMajorConfirmInfo,
  updateMajorConfirmInfo,
  getRiskTropism,
  updateRiskTropism,
  getRiskTropismAssessResult,
  updateRiskConfirmInfo,
  getRiskTellStatus,
  updateRiskTellStatus,
  getRiskTellSign,
  getVideoType,
  updateRiskTellSign,
  getImageResourcesStatus,
  submitInfo,
  getImageResource,
  uploadVideo,
  uploadPdf,
  updateImageResource,
};
