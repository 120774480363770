export default {
  namespace: true,
  state: {
    pageData: {},
  },
  mutations: {
    setPageData(state, payload) {
      state.pageData = payload;
    },
  },
};
