import axios from 'axios';
import qs from 'qs';
import { Toast } from 'vant';
import CookieSet from './cookie';
import store from '../store';

const showToast = (message) => {
  Toast({ message, duration: 3000, forbidClick: true });
};
const logger = (err) => {
  // eslint-disable-next-line
  console.log(err);
};
const showLoading = () => {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 60000,
  });
};
const hideLoading = () => {
  Toast.clear();
};

const isMock = false;
const prefix = isMock ? '/mock' : '';
// const prefix = process.env.REACT_APP_ISMOCK === 'true' ? '/mock' : '';

axios.defaults.timeout = 60000; // 超时限制60s ---香港网络上传视频访问太慢存在超过20S的清空

axios.interceptors.response.use(
  (response) => {
    store.commit('setCookiePhone', CookieSet.encode(CookieSet.get('phone')));
    store.commit('setCookieExpires', new Date().valueOf() + 28800000);
    hideLoading();
    // 数据相应成功 增加一些提示
    const { msg } = response.data;
    const code = response.data.code || response.data.errcode;
    if (code >= 200 && code < 300) {
      // 正常数据
    } else if (code === 403) {
      const toast = msg || '登录过期，请重新登录';
      showToast(toast);
      setTimeout(() => {
        const { isDark, platform } = store.state.user;
        const path_query = `/login${isDark && platform === 'APP' ? '?platform=AppDark' : ''}`;
        window.location.replace(`${window.location.origin}${path_query}`);
      }, 300);
    } else if (code === 404) {
      showToast(msg || '网络请求不存在');
    } else if (code) {
      showToast(msg || '网络异常，请稍后再试');
    }
    return response;
  },
  (error) => {
    hideLoading();
    // https://juejin.cn/post/6844903585751236621
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      showToast('网络连接超时');
    } else if (error.message.indexOf('404') !== -1) {
      showToast('网络请求不存在');
    } else {
      showToast('网络异常，请稍后再试');
    }
    return Promise.reject(error);
  },
);

export const Post = (url, params = {}, { loading = true } = {}) => new Promise((resolve) => {
  if (loading) showLoading();
  axios.post(`${prefix}${url}`, qs.stringify(params))
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      logger(err);
      resolve();
    });
});

export const PostJSON = (
  url,
  params = {},
  {
    headers = { 'Content-Type': 'application/json' },
    loading = true,
  } = {},
) => new Promise((resolve) => {
  if (loading) showLoading();
  axios.post(`${prefix}${url}`, JSON.stringify(params), { headers })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      logger(err);
      resolve();
    });
});

export const PostForm = (url, params = {}, { loading = true } = {}) => new Promise((resolve) => {
  if (loading) showLoading();
  axios.post(`${prefix}${url}`, qs.stringify(params), { headers: { 'Content-type': 'application/x-www-form-urlencoded' } })
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      logger(err);
      resolve();
    });
});

export const PostFile = (url, params = {}, { loading = true } = {}) => new Promise((resolve) => {
  if (loading) showLoading();
  axios.post(`${prefix}${url}`, params, { headers: { 'Content-type': 'application/x-www-form-urlencoded' } })
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      logger(err);
      resolve();
    });
});

export const Get = {};
